import { IconBrandInstagram, IconBrandLinkedin, IconBrandPinterest, IconBrandX } from '@tabler/icons-react';

export default function SocialBrands({ color }) {
  return (
    <>
      <a href="https://www.instagram.com/goldframer" target="_blank" rel="noreferrer">
        <IconBrandInstagram color={color ? color : 'var(--color-dim)'} stroke={1} size={40} />
      </a>
      <a href="https://www.linkedin.com/company/goldframer/" target="_blank" rel="noreferrer">
        <IconBrandLinkedin color={color ? color : 'var(--color-dim)'} stroke={1} size={40} />
      </a>
      <a href="https://x.com/GoldframerArt" target="_blank" rel="noreferrer">
        <IconBrandX color={color ? color : 'var(--color-dim)'} stroke={1} size={35} />
      </a>
      <a href="https://tr.pinterest.com/goldframerart/" target="_blank" rel="noreferrer">
        <IconBrandPinterest color={color ? color : 'var(--color-dim)'} stroke={1} size={35} />
      </a>
    </>
  );
}
